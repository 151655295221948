import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
const Services = () => {
  return (
    <>
    <Header />
   
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*header section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">Our Services</h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/services">Service</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*header section end*/}
    {/*services section start*/}
    <section className="services-section ptb-100 gray-light-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center mb-4">
              <h2>First Class HR Solutions for You</h2>
              <span className="animate-border mr-auto ml-auto mb-4" />
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/application.png" alt="services" width={60} />
                <h5>HR consulting</h5>
                <p className="mb-0">
                Optimized talent acquisition, enhanced employee engagement, streamlined processes, compliance assurance, improved organizational performance, and team collaboration.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/maintenance.png" alt="services" width={60} />
                <h5>Technical recruitment </h5>
                <p className="mb-0">
                Technical recruitment ensures efficient talent acquisition, reduced hiring time, enhanced candidate quality, skill alignment, and team productivity improvement.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/software.png" alt="services" width={60} />
                <h5>Non IT recruitment</h5>
                <p className="mb-0">
                Non-technical recruitment achieves strong cultural fit, improved candidate diversity, streamlined hiring, reduced turnover, and enhanced role alignment.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/mobile-app.png" alt="services" width={60} />
                <h5>HR planning</h5>
                <p className="mb-0">
                Delorb ensures strategic workforce alignment, efficient resource allocation, reduced hiring costs, and improved retention rates through HR planning.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/jigsaw.png" alt="services" width={60} />
                <h5>Interview coordination</h5>
                <p className="mb-0">
                We ensure smooth scheduling, enhance candidate experience, minimize delays, maintain communication, and support hiring.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/consultation.png" alt="services" width={60} />
                <h5>Consultation</h5>
                <p className="mb-0">
                Delorb provides expert guidance, identifies challenges, offers tailored solutions, facilitates decision-making, and drives strategic improvements.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
          </div>
      </div>
    </section>
    {/*services section end*/}
    {/*promo section start*/}
    <section className="promo-section ptb-100">
      <div className="container">
        <div className="col-md-8">
          <div className="section-heading text-center mb-4">
            <h2>Best Services For Recruitment</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-3">
            <div className="promo-single-wrap p-5 text-center custom-shadow rounded">
              <div className="promo-icon mb-4">
                <img src="img/job-search.png" alt="services" width={60} />
                <span className="number-bg">01</span>
              </div>
              <div className="promo-info">
                <h4>Candidate Sourcing</h4>
                <p>
                  Utilize diverse channels for sourcing top talent globally,
                  ensuring a robust pool of qualified candidates for client
                  needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="promo-single-wrap p-5 text-center custom-shadow rounded">
              <div className="promo-icon mb-4">
                <img src="img/candidate.png" alt="services" width={60} />
                <span className="number-bg">02</span>
              </div>
              <div className="promo-info">
                <h4>Technical &amp; Screening</h4>
                <p>
                  Rigorous screening processes and assessments to match skills,
                  culture fit, and career aspirations with client requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="promo-single-wrap p-5 text-center custom-shadow rounded">
              <div className="promo-icon mb-4">
                <img src="img/interview.png" alt="services" width={60} />
                <span className="number-bg">03</span>
              </div>
              <div className="promo-info">
                <h4>Interview Coordination</h4>
                <p>
                  Efficient coordination of interviews, ensuring seamless
                  communication between candidates and hiring teams.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="promo-single-wrap p-5 text-center custom-shadow rounded">
              <div className="promo-icon mb-4">
                <img src="img/candidate.png" alt="services" width={60} />
                <span className="number-bg">04</span>
              </div>
              <div className="promo-info">
                <h4>Screening &amp; Evaluation</h4>
                <p>
                  Rigorous screening processes and assessments to match skills,
                  culture fit, and career aspirations with client requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*promo section end*/}
  </div>
  {/*body content wrap end*/}
</>

    <Footer />
  </>
  
  );
};

export default Services;
import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
const Faq = () => {
  return (
    <>
    <Header />
   
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*hero section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">FAQ Page</h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/Faq">FAQ</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*hero section end*/}
    {/*promo section start*/}
    <section className="promo-section ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-9">
            <div className="section-heading mb-5">
              <h2>Frequently Asked Questions</h2>
              <p className="lead">
                Quickly morph client-centric results through performance based
                applications. Proactively facilitate professional human capital
                for cutting-edge.
              </p>
            </div>
          </div>
        </div>
        {/*pricing faq start*/}
        <div className="row">
          <div className="col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              {/* Accordion card 1 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3" /> Which license do I
                    need?
                  </h6>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Uniquely leverage other's distinctive infomediaries rather
                      than leveraged supply chains. Continually seize
                      distributed collaboration and idea-sharing whereas user.
                    </p>
                  </div>
                </div>
              </div>
              {/* Accordion card 2 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-gallery mr-3" /> How do I get access to
                    a theme?
                  </h6>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Rapidiously incentivize virtual e-commerce and exceptional
                      e-tailers. Progressively network focused catalysts for
                      change without orthogonal benefits. Dramatically empower.
                    </p>
                  </div>
                </div>
              </div>
              {/* Accordion card 3 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3" /> How do I see previous
                    orders?
                  </h6>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Proactively monetize long-term high-impact innovation and
                      scalable relationships. Dynamically mesh
                      principle-centered functionalities before next-generation
                      best practices. Distinctively empower.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              {/* Accordion card 1 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3" /> Which license do I
                    need?
                  </h6>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Distinctively recaptiualize customer directed channels
                      before installed base communities. Continually
                      disintermediate distinctive web services vis-a-vis team
                      building e-commerce.
                    </p>
                  </div>
                </div>
              </div>
              {/* Accordion card 2 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-lock mr-3" /> How do I get access to a
                    theme?
                  </h6>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Quickly recaptiualize revolutionary meta-services and
                      multimedia based channels. Seamlessly impact diverse
                      deliverables rather than cooperative strategic theme
                      areas.
                    </p>
                  </div>
                </div>
              </div>
              {/* Accordion card 3 */}
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3" /> How do I see previous
                    orders?
                  </h6>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Efficiently supply B2B networks vis-a-vis best-of-breed
                      schemas. Dramatically parallel task reliable technology
                      with cross functional core competencies.
                      Phosfluorescently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*pricing faq end*/}
      </div>
    </section>
    {/*promo section end*/}
  </div>
  {/*body content wrap end*/}
</>

    <Footer />
  </>
  
  );
};

export default Faq;
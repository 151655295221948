
import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
    <Header />
    {/*body content wrap start*/}
    <div className="main">
      {/*hero section start*/}
      <section className="ptb-70 gradient-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-5">
              <div className="hero-slider-content text-white pt-5">
                {/* <strong>Digital Marketing</strong> */}
                <h1 className="text-white">Grow Your Business With Delorb</h1>
                <p className="lead">
                  Holisticly procrastinate mission-critical convergence with
                  reliable customer service. Assertively underwhelm for impactful
                  solutions.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-7">
              <div className="image-wrap pt-5">
                <img
                  src="img/hit.png"
                  className="img-fluid custom-width"
                  alt="hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*hero section end*/}
      {/*about us section start*/}
      <section className="about-us-section ptb-70 gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="about-us-img">
                <img
                  src="img/about-img-one1.png"
                  alt="about us"
                  className="img-fluid about-single-img"
                />
                {/* <img src="img/about-us-img-bg.svg" alt="about bg" class="about-us-bg"> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="about-us-content-wrap">
                <strong className="color-secondary">About Us</strong>
                <h2>Grow Business With Delorb</h2>
                <span className="animate-border mb-4" />
                <p>
                  Delorb is a leading HR consultancy offering a wide range of
                  services tailored to optimize organizational performance.
                  Specializing in staffing, recruiting, and comprehensive training
                  programs, we excel in IT development, manpower supply, and
                  ensuring labor law compliance. Our expertise extends to skill
                  and competency development, manpower planning and detailed
                  productivity analysis. Dedicated to delivering holistic human
                  resource management solutions, Delorb is committed to empowering
                  companies with the tools and strategies necessary for sustained
                  growth and success.
                </p>
           
              </div>
              <div className="action-btns mt-4">
                {/* <a href="#" className="btn secondary-solid-btn mr-3">
                  Start Now
                </a> */}
                <Link className="btn secondary-solid-btn mr-3" to="/about">Start Now</Link>


              </div>
            </div>
          </div>
        </div>
      </section>
      {/*about us section end*/}
      {/*about us section start*/}
      <section className="about-us-section new ptb-60">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-12 col-lg-7">
              <div className="about-us-content-wrap">
                <h2>For years we have been employing creative results.</h2>
                <span className="animate-border mb-4" />
                <p>
                  Delorb has been a leader in delivering creative and impactful
                  solutions for years. With a strong foundation built over time,
                  Delorb has consistently showcased its commitment to innovation
                  across diverse domains. By seamlessly integrating creativity
                  with advanced technology, the company has established itself as
                  a distinguished player in the industry. As Delorb continues its
                  forward momentum, its proven track record of delivering
                  exceptional results highlights its enduring influence on the
                  business landscape.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-5">
              <div className="card border-0 text-white">
                <img
                  src="img/creative.png"
                  style={{ height: 450 }}
                  alt="video"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*about us section end*/}
      {/*services section start*/}
      <section className="services-section ptb-70 gray-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-4">
                {/* <strong class="color-secondary">Our Services</strong> */}
                <h2>HR  Services We Provide</h2>
                <span className="animate-border mr-auto ml-auto mb-4" />
                {/* <p class="lead">Efficiently aggregate end-to-end core competencies without maintainable ideas. Dynamically
                        foster tactical solutions without enabled value.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/application.png" alt="services" width={60} />
                <h5>HR consulting</h5>
                <p className="mb-0">
                Optimized talent acquisition, enhanced employee engagement, streamlined processes, compliance assurance, improved organizational performance, and team collaboration.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/maintenance.png" alt="services" width={60} />
                <h5>Technical recruitment </h5>
                <p className="mb-0">
                Technical recruitment ensures efficient talent acquisition, reduced hiring time, enhanced candidate quality, skill alignment, and team productivity improvement.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/software.png" alt="services" width={60} />
                <h5>Non IT recruitment</h5>
                <p className="mb-0">
                Non-technical recruitment achieves strong cultural fit, improved candidate diversity, streamlined hiring, reduced turnover, and enhanced role alignment.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/mobile-app.png" alt="services" width={60} />
                <h5>HR planning</h5>
                <p className="mb-0">
                Delorb ensures strategic workforce alignment, efficient resource allocation, reduced hiring costs, and improved retention rates through HR planning.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/jigsaw.png" alt="services" width={60} />
                <h5>Interview coordination</h5>
                <p className="mb-0">
                We ensure smooth scheduling, enhance candidate experience, minimize delays, maintain communication, and support hiring.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="services-single p-5 my-md-3 my-lg-3 my-sm-0 shadow-sm rounded white-bg">
                <img src="img/consultation.png" alt="services" width={60} />
                <h5>Consultation</h5>
                <p className="mb-0">
                Delorb provides expert guidance, identifies challenges, offers tailored solutions, facilitates decision-making, and drives strategic improvements.
                </p>
                <Link className="detail-link mt-4" to="/services">Read more<span className="ti-arrow-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*services section end*/}
      {/*why choose us section start*/}
      <section className="why-choose-us ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="section-heading text-center">
                {/* <strong class="color-secondary">Why Choose Us</strong> */}
                <h2>Your Trusted Partner for Innovative HR Solutions!</h2>
                <span className="animate-border mr-auto ml-auto mb-4" />
                <p className="lead">
                  Your trusted partner for innovative IT solutions, delivering
                  tailored strategies, cutting-edge technology, and exceptional
                  support to drive success, streamline operations, and empower
                  your business in a competitive digital landscape.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-5">
              <div className="image-wrap">
                <img
                  src="img/partner.png"
                  alt="why choose us"
                  className="img-fluid m-auto"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="why-choose-us-wrap">
                <div className="row">
                  <div className="col-md-6 col-sm-6 promo-col">
                    <div className="promo-item">
                      <div className="promo-img">
                        <img src="img/data.png" alt="services" width={60} />
                      </div>
                      <h5>Leading the HR Revolution</h5>
                      <p>
                      By embracing innovation, enhancing diversity and automating processes, we lead the best Recruitment solutions
                      </p>
                      <div className="promo-bottom-shape">
                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                      </div>
                    </div>
                    <div className="promo-item">
                      <div className="promo-img">
                        <img src="img/connection.png" alt="services" width={60} />
                      </div>
                      <h5>Effortless Connectivity</h5>
                      <p>
                        Our intuitive platform ensures a smooth, hassle-free
                        experience, connecting you to cutting-edge solutions with
                        ease.
                      </p>
                      <div className="promo-bottom-shape">
                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 promo-col">
                    <div className="promo-item">
                      <div className="promo-img">
                        <img
                          src="img/global-network.png"
                          alt="services"
                          width={60}
                        />
                      </div>
                      <h5>IT Recruitment</h5>
                      <p>
                        Partner with a team that delivers unparalleled IT services
                        across the globe, empowering businesses to thrive
                        worldwide.
                      </p>
                      <div className="promo-bottom-shape">
                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                      </div>
                    </div>
                    <div className="promo-item">
                      <div className="promo-img">
                        <img
                          src="img/technical-support.png"
                          alt="services"
                          width={60}
                        />
                      </div>
                      <h5>Around-the-Clock Support</h5>
                      <p>
                        We're here for you 24/7, providing continuous support to
                        keep your operations running smoothly, no matter the time
                        zone.
                      </p>
                      <div className="promo-bottom-shape">
                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*why choose us section end*/}
      <div className="feat bg-gray pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>
                <span>Why Choose</span> Us?
              </h4>
              <p>
                Delorb provides top-notch internet marketing and web development
                services, delivering tailored solutions that drive impactful and
                positive results for businesses.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/achievement.png" alt="services" width={60} />
                <h6>Our Achievement</h6>
                <p>
                HR consultancy firm driving impactful conversions and fostering sustainable growth through innovative strategies, exceptional solutions, and consistently outstanding results.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/design-thinking.png" alt="services" width={60} />
                <h6>Creativity &amp; Innovation</h6>
                <p>
                Our team's creativity drives a unique recruitment process and strategy, enhancing the smooth selection procedure through innovative and distinctive approach.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/high-quality.png" alt="services" width={60} />
                <h6>We Value Quality</h6>
                <p>
                  {" "}
                  
We prioritize exceptional quality, delivering remarkable, lasting results. Our focused approach ensures sustainable success, always valuing excellence over sheer volume.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/data-discovery.png" alt="services" width={60} />
                <h6>Impactful Recruitment</h6>
                <p>
                  {" "}
                  

                 
Impactful recruitment strategies and effective screening ensure optimal resource management, improved productivity, and superior outcomes for clients.







                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/data-analysis.png" alt="services" width={60} />
                <h6>Market Strategy</h6>
                <p>
                  Embracing innovation over outdated models ensures progress, much
                  like prioritizing engines over horseshoes for transformative
                  market strategies.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item">
                {" "}
                <img src="img/planning.png" alt="services" width={60} />
                <h6>Planning</h6>
                <p>
                We craft effective hr solutions, planning, and aligning the needs to achieve clear, measurable results with precision and purpose.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    




      <div className="testimonial-section"
      style={{
        background:
          'url("img/testimonial-bg.png")no-repeat center center / cover'
      }}>
  <h1>What Our Clients Say</h1>
  <div className="testimonial-carousel">
    <div className="testimonial-card">
      <div className="client-image">
        <img src="img/aas.webp" alt="John Doe" />
      </div>
      <p className="testimonial-text">
        "I am delighted to express my profound satisfaction with the services rendered by Delorb. Their team exhibited a profound grasp of our organizational requirements and consistently presented candidates who not only met our technical specifications but also seamlessly integrated with our company culture. The recruitment process, under their expert guidance, was executed with remarkable efficiency and professionalism, ensuring a smooth and productive experience. I unequivocally endorse Delorb as a premier recruitment partner for any discerning organization."
      </p>
      <h3>Maithlish Pattnaik</h3>
      <p className="client-role">Managing Director</p>
    </div>

    <div className="testimonial-card">
      <div className="client-image">
        <img src="img/client.jpeg" alt="Jane Smith" />
      </div>
      <p className="testimonial-text">
        "Delorb's team demonstrated an acute understanding of our organizational objectives and effectively sourced candidates who excelled both in skill and cultural fit. The process was characterized by their exceptional professionalism, attention to detail, and commitment to excellence. Their strategic approach and insightful recommendations have been instrumental in fulfilling our talent needs and advancing our business goals."
      </p>
      <h3>Janmejay Mohapatra</h3>
      <p className="client-role">CEO</p>
    </div>

    {/* <div className="testimonial-card">
      <div className="client-image">
        <img src="https://via.placeholder.com/100" alt="Michael Brown" />
      </div>
      <p className="testimonial-text">
        "A reliable and professional team that truly understands client
        requirements."
      </p>
      <h3>Michael Brown</h3>
      <p className="client-role">Project Lead, BuildWorks</p>
    </div> */}
  </div>
</div>

      {/*blog section start*/}
      <section className="our-blog-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="section-heading text-center mb-5">
                <strong className="color-secondary">New and Blog</strong>
                <h2>Our Latest News</h2>
                <span className="animate-border mr-auto ml-auto mb-4" />
                <p className="lead">
                  Enthusiastically drive revolutionary opportunities before
                  emerging leadership. Distinctively transform tactical methods of
                  empowerment via resource.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/AI.jpeg" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>10</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Unlocking-Career-Potential-How-Learning-AI-Technology-Can-Lead-to-a-Better-Future">Unlocking Career Potential: How Learning AI Technology Can
                    Lead to a Better Future</Link>
                  </h3>
                  
                  <Link className="detail-link" to="/Unlocking-Career-Potential-How-Learning-AI-Technology-Can-Lead-to-a-Better-Future">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/cloud-computing-e-degree.jpg" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>12</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Empowering-the-Future-How-Learning-Cloud-Technology-Can-Lead-to-a-Better-Tomorrow">Empowering the Future: How Learning Cloud Technology Can Lead
                    to a Better Tomorrow</Link>
                  </h3>
                 
                  <Link className="detail-link" to="/Empowering-the-Future-How-Learning-Cloud-Technology-Can-Lead-to-a-Better-Tomorrow">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/AI-CHATBOT.png" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>15</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Creating-an-AI-Bot-for-the-Future-Opportunities-Challenges-and-How-to-Get-Started">Creating an AI Bot for the Future: Opportunities, Challenges,
                    and How to Get Started</Link>
                  </h3>
                 
                  <Link className="detail-link" to="/Creating-an-AI-Bot-for-the-Future-Opportunities-Challenges-and-How-to-Get-Started">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*blog section end*/}
    </div>

    <Footer />
  </>
  
  );
};

export default Home;



import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import ScrollToTop from './ScrollToTop';
import Faq from './Pages/Faq';
import Blog from './Pages/Blog';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Career from './Pages/Career';
import Blog1 from './Pages/Blog1';
import Blog2 from './Pages/Blog2';
import Blog3 from './Pages/Blog3';


function App() {
  return (
    <Router>
         <ScrollToTop />
    <div className="App">
 
      {/* Define the routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

          <Route path="/services" element={<Services />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Unlocking-Career-Potential-How-Learning-AI-Technology-Can-Lead-to-a-Better-Future" element={<Blog1 />} />

          <Route path="/Empowering-the-Future-How-Learning-Cloud-Technology-Can-Lead-to-a-Better-Tomorrow" element={<Blog2 />} />

          <Route path="/Creating-an-AI-Bot-for-the-Future-Opportunities-Challenges-and-How-to-Get-Started" element={<Blog3 />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;

import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <>
    <Header />
   
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*header section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">Blog </h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/blog">Blog</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*header section end*/}
  {/*blog section start*/}
  <section className="our-blog-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/AI.jpeg" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>10</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Unlocking-Career-Potential-How-Learning-AI-Technology-Can-Lead-to-a-Better-Future">Unlocking Career Potential: How Learning AI Technology Can
                    Lead to a Better Future</Link>
                  </h3>
                  
                  <Link className="detail-link" to="/Unlocking-Career-Potential-How-Learning-AI-Technology-Can-Lead-to-a-Better-Future">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/cloud-computing-e-degree.jpg" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>12</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Empowering-the-Future-How-Learning-Cloud-Technology-Can-Lead-to-a-Better-Tomorrow">Empowering the Future: How Learning Cloud Technology Can Lead
                    to a Better Tomorrow</Link>
                  </h3>
                 
                  <Link className="detail-link" to="/Empowering-the-Future-How-Learning-Cloud-Technology-Can-Lead-to-a-Better-Tomorrow">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <div className="blog-img position-relative">
                  <img src="img/AI-CHATBOT.png" className="card-img-top" alt="blog" />
                  <div className="meta-date">
                    <strong>15</strong>
                    <small>Nov</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">
                        <i className="fas fa-heart mr-2" />
                        <span>45</span>
                        Comments
                      </li>
                      <li className="list-inline-item">
                        <i className="fas fa-share-alt mr-2" />
                        <span>10</span>
                        Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 mb-2 card-title">
                    <Link to="/Creating-an-AI-Bot-for-the-Future-Opportunities-Challenges-and-How-to-Get-Started">Creating an AI Bot for the Future: Opportunities, Challenges,
                    and How to Get Started</Link>
                  </h3>
                 
                  <Link className="detail-link" to="/Creating-an-AI-Bot-for-the-Future-Opportunities-Challenges-and-How-to-Get-Started">Read more<span className="ti-arrow-right" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*blog section end*/}
  </div>
  {/*body content wrap end*/}
</>

    <Footer />
  </>
  
  );
};

export default Blog;
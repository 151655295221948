
import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const About = () => {
  return (
    <>
    <Header />
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*header section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">About Us</h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="list-inline-item breadcrumb-item">
                    <a href="about.html">About</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*header section end*/}
    {/*about us section start*/}
    <section className="about-us-section ptb-70 gray-light-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="about-us-img">
              <img
                src="img/about-img-one1.png"
                alt="about us"
                className="img-fluid about-single-img"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="about-us-content-wrap">
              <strong className="color-secondary">About Us</strong>
              <h2>Grow Business With Delorb</h2>
              <span className="animate-border mb-4" />
              <p>
                Delorb is a leading HR consultancy offering a wide range of
                services tailored to optimize organizational performance.
                Specializing in staffing, recruiting, and comprehensive training
                programs, we excel in IT development, manpower supply, and
                ensuring labor law compliance. Our expertise extends to skill
                and competency development, manpower planning and detailed
                productivity analysis. Dedicated to delivering holistic human
                resource management solutions, Delorb is committed to empowering
                companies with the tools and strategies necessary for sustained
                growth and success.
              </p>
              <ul className="list-unstyled tech-feature-list">
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Specializing in human resource management strategies tailored
                  to optimize company performance.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Offering expert advice on workforce planning and development.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Proven track record in sourcing top talent across various
                  industries.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Customized recruitment solutions to meet specific
                  organizational needs.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Comprehensive programs designed to enhance skills and
                  competencies.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Innovative solutions for IT infrastructure and software
                  development.
                </li>
                <li className="py-1">
                  <span className="ti-control-forward mr-2 color-secondary" />
                  Supporting digital transformation initiatives through
                  cutting-edge technology.
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </section>
    {/*about us section end*/}
  </div>
  {/*body content wrap end*/}
</>


    <Footer />
  </>
  
  );
};

export default About;

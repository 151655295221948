import React from 'react';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <header className="header">
  <div id="header-top-bar" className="primary-bg py-2">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-7 col-lg-7 d-none d-md-block d-lg-block">
          <div className="topbar-text text-white">
            <ul className="list-inline">
              <li className="list-inline-item">
                <span className="fas fa-headphones-alt mr-1" /> 24x7 Technical
                Support
              </li>
              <li className="list-inline-item">
                <span className="fas fa-phone-alt mr-1" /> +91 94 39958992
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-lg-4">
          <div className="topbar-text text-white">
            <ul className="list-inline text-md-right text-lg-right text-left  mb-0">
              
          
              <li className="list-inline-item">
                <Link to="https://www.linkedin.com/company/delorb-cpl/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BzigUwMA1SUy69uiggBY93A%3D%3D" className="rounded" target="_blank">
                  <span className="fab fa-linkedin-in" />
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="https://www.instagram.com/delorb_cpl?igsh=amg0MXd6NG00NWsy" target="_blank" className="rounded">
                  <span className="fab fa-instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav className="navbar navbar-expand-lg fixed-top white-bg">
    <div className="container">
      <Link className="navbar-brand" to="/">
        <img src="img/Delorb-logo.png" alt="logo" className="img-fluid" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="ti-menu" />
      </button>
      <div
        className="collapse navbar-collapse h-auto"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ml-auto menu">
          <li>
          <Link to="/">Home</Link>
          </li>
          <li>
          <Link to="/about">About Us</Link>
          </li>
          <li>
          <Link to="/services">Service</Link>
          </li>
          <li>
          <Link to="/blog">Blog</Link>
          </li>
          <li>
          <Link to="/career">Career</Link>
          </li>
          <li>
          <Link to="/Faq">FAQ</Link>
          </li>
          <li>
          <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

  );
};

export default Header;



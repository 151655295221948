// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
  <footer className="footer-section">
    {/*footer top start*/}
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="row footer-top-wrap">
              <div className="col-md-4 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <img src="img/delorb footer.png" alt="logo" className="mb-2" />
                  <p>
                  Welcome to Delorb, We offer the best, most affordable products and services around .
                  </p>
                  <div className="social-nav mt-4">
                    <ul className="list-unstyled social-list mb-0">
               
                      <li className="list-inline-item tooltip-hover">
                      <Link className="rounded" target="_blank" to="https://www.linkedin.com/company/delorb-cpl/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BzigUwMA1SUy69uiggBY93A%3D%3D">   <span className="ti-linkedin" /></Link>
                        <div className="tooltip-item">Linkedin</div>

  
                      </li>
                      <li className="list-inline-item tooltip-hover">
                      <Link className="rounded" target="_blank" to="https://www.instagram.com/delorb_cpl?igsh=amg0MXd6NG00NWsy">       <span className="ti-instagram" /></Link>
                        <div className="tooltip-item">Instagram</div>

                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">Our Links</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/services">Services</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/blog">blog</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">LEGAL</h4>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Legal Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="row footer-top-wrap mt-md-4 mt-sm-0 mt-lg-0">
              <div className="col-12">
                <div className="footer-nav-wrap text-white">
                  <h4 className="text-white">GET IN TOUCH</h4>
                  <ul className="get-in-touch-list">
                    <li className="d-flex align-items-center py-2">
                      <span className="fas fa-map-marker-alt mr-2" />
                      Pokhariput,Bhubaneshwar
                    </li>
                    <li className="d-flex align-items-center py-2">
                      <span className="fas fa-envelope mr-2" /> support@delorb.in
                    </li>
                    {/*<li class="d-flex align-items-center py-2"><span class="fas fa-phone-alt mr-2"></span> (123) 456-7890 - (123) 456-7890</li>*/}
                  </ul>
                  <form className="newsletter-form mt-3">
                    <input
                      type="text"
                      className="input-newsletter"
                      placeholder="Enter your email"
                      name="EMAIL"
                      required=""
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      className="disabled"
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                    >
                      <i className="fas fa-paper-plane" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*footer top end*/}
    {/*footer copyright start*/}
    <div className="footer-bottom gray-light-bg py-2">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-5 col-lg-5">
            <p className="copyright-text pb-0 mb-0">
              Copyrights © 2024. All rights reserved by

              <Link  target="_blank" to="/">Delorb</Link>
            </p>
          </div>
         
        </div>
      </div>
    </div>
    {/*footer copyright end*/}
  </footer>
  {/* <button className="scroll-top scroll-to-target" data-target="html">
    <span className="ti-angle-up" />
  </button> */}
</>

  );
};

export default Footer;
























import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
const Contact = () => {
  return (
    <>
    <Header />
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*header section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">Contact Us</h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>
                  <li className="list-inline-item breadcrumb-item active">
                    Contact Us
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*header section end*/}
    {/*contact us promo start*/}
    <section className="contact-us-promo pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card single-promo-card single-promo-hover text-center shadow-sm">
              <div className="card-body py-5">
                <div className="pb-2">
                  <span className="ti-mobile icon-sm color-secondary" />
                </div>
                <div>
                  <h5 className="mb-0">Call Us</h5>
                  <p className="text-muted mb-0">+91 94 39958992</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card single-promo-card single-promo-hover text-center shadow-sm">
              <div className="card-body py-5">
                <div className="pb-2">
                  <span className="ti-location-pin icon-sm color-secondary" />
                </div>
                <div>
                  <h5 className="mb-0">Visit Us</h5>
                  <p className="text-muted mb-0">Pokhariput,Bhubaneshwar</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card single-promo-card single-promo-hover text-center shadow-sm">
              <div className="card-body py-5">
                <div className="pb-2">
                  <span className="ti-email icon-sm color-secondary" />
                </div>
                <div>
                  <h5 className="mb-0">Mail Us</h5>
                  <p className="text-muted mb-0">support@delorb.in</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                  <div class="card-body py-5">
                      <div class="pb-2">
                          <span class="ti-headphone-alt icon-sm color-secondary"></span>
                      </div>
                      <div><h5 class="mb-0">Live Chat</h5>
                          <p class="text-muted mb-0">Chat with Us 24/7</p></div>
                  </div>
              </div>
          </div> */}
        </div>
      </div>
    </section>
    {/*contact us promo end*/}
    {/*contact us section start*/}
    <section className="contact-us-section ptb-100">
      <div className="container contact">
        <div className="col-12 pb-3 message-box d-none">
          <div className="alert alert-danger" />
        </div>
        <div className="row justify-content-around">
          <div className="col-md-6">
            <div className="contact-us-form gray-light-bg rounded p-5">
              <h4>Ready to get started?</h4>
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows={7}
                        cols={25}
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn secondary-solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-5">
            <div className="contact-us-content">
              <h2>Looking for a excellent Business idea?</h2>
              {/* <p className="lead">
                Seamlessly deliver pandemic e-services and next-generation
                initiatives.
              </p> */}
              {/* <a href="#" class="btn outline-btn align-items-center">Get Directions <span class="ti-arrow-right pl-2"></span></a> */}
              {/* <hr class="my-5"> */}
              <h5>Our Headquarters</h5>
              <address>
                PLOT NO-300/940, Pokhariput, <br />
                Aerodrome Area, Bhubaneshwar, Khorda, Odisha - 751020
              </address>
              <br />
              <span>Phone: +91 94 39958992</span> <br />
              <span>
                Email:{" "}
                <a href="mailto:support@delorb.in" className="link-color">
                  support@delorb.in
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*contact us section end*/}
    {/*google map block start*/}
    <div className="google-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14973.464426168746!2d85.8011056132336!3d20.243645781792345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a70b86139c21%3A0x7de3ce85b7654f!2sPokhariput%2C%20Bhubaneswar!5e0!3m2!1sen!2sin!4v1720276567094!5m2!1sen!2sin"
        width={416}
        height={570}
        style={{ border: 0 }}
        allowFullScreen=""
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
    {/*google map block end*/}
  </div>
  {/*body content wrap end*/}
</>


    <Footer />
  </>
  
  );
};

export default Contact;
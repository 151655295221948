import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
const Career = () => {
  return (
    <>
    <Header />
    <>

  <div className="main">
    {/*header section start*/}
    <section
      className="hero-section ptb-100 gradient-overlay"
      style={{
        background: 'url("img/header-bg-5.jpg")no-repeat center center / cover'
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
              <h1 className="text-white mb-0">Career</h1>
              <div className="custom-breadcrumb">
                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                  <li className="list-inline-item breadcrumb-item">
                  <Link to="/">Home</Link>
                  </li>
                  <li className="list-inline-item breadcrumb-item active">
                   Career
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
 
    <>
  {/*body content wrap start*/}
  <div className="main">
    {/*hero background image with content slider start*/}
    <section className="hero-section  ptb-100 full-screen">
      <div className="container">
        <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
         
          <div className="col-md-5 col-lg-10">
            <div className="card login-signup-card shadow-lg mb-0">
              <div className="card-body px-md-5 py-5">
                
                <form className="login-signup-form">
                  <div className="form-group">
                    {/* Label */}
                    <label className="pb-1">Your Name</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-user color-primary" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    {/* Label */}
                    <label className="pb-1">Email Address</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-email color-primary" />
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="name@address.com"
                      />
                    </div>
                  </div>
                  {/* Password */}
                  <div className="form-group">
                    {/* Label */}
                    <label className="pb-1">Phone</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>

  {/* Password */}
  <div className="form-group">
                    {/* Label */}
                    <label className="pb-1">Upload Resume</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <span className="ti-lock color-primary" />
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        
                      />
                    </div>
                  </div>

                  <div className="my-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check-terms"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-terms"
                      >
                        I agree to the <a href="#">Terms &amp; Conditions</a>
                      </label>
                    </div>
                  </div>
                  {/* Submit */}
                  <button className="btn btn-block secondary-solid-btn border-radius mt-4 mb-3">
                    Submit
                  </button>
                </form>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*hero background image with content slider end*/}
  </div>
  {/*body content wrap end*/}
</>

</>

    <Footer />
  </>
  
  );
};

export default Career;